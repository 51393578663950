import { Badge, Button } from "@bosch/react-frok";
import style from "./CartButton.module.scss";
import { useHistory } from "react-router-dom";
import { MY_SHOPPING_CART_SHARED_ROUTE } from "../../../constants/routes/sharedRoutes.constants";
import { CartConstants } from "../../../helpers/CartConstants";
import { useShoppingCart } from "../../../hooks/useShoppingCart";
import { useTranslation } from "@localization/contexts/i18nContext";

const CartButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { itemCount } = useShoppingCart();

  const onGoToShoppingCart = () => {
    history.push(MY_SHOPPING_CART_SHARED_ROUTE.path);
  };

  const renderCartQuantity = () => {
    return itemCount > CartConstants.CART_BADGE_MAX_QUANTITY
      ? CartConstants.CART_OVER_MAX_QUANTITY_LABEL
      : itemCount;
  };

  return (
    <Button
      mode="integrated"
      className={style.container}
      icon="shoppingcart"
      onClick={onGoToShoppingCart}
    >
      {itemCount > 0 ? (
        <Badge label={renderCartQuantity()} className={style.cart_quantity} />
      ) : (
        <></>
      )}
      <span>{t("Footer_ShoppingCart")}</span>
    </Button>
  );
};

export default CartButton;
