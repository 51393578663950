import { FC, Fragment } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { Image } from "semantic-ui-react";
import boschSupergraphic from "../../../assets/Bosch-Supergraphic_.png";
import style from "./ForbiddenPage.module.css";

const ForbiddenPage: FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      <div className="grid-responsive-width">
        {/* <div className="header-large-box">
          <Image src={logo} as="a" href="/"></Image>
          <h5 className="landing-page-title">
            {t("LandingPage_Header_Title")}
          </h5>
        </div> */}

        <div className={`${style.maintenance_message}`}>
          <p className={`${style.maintenance_message_title}`}>
            <h1>Forbidden</h1>
          </p>
          <p className={`${style.info_message}`}>
            <span>
              You don't have permission to access Bosch Automotive Training
              Solutions.
            </span>
          </p>
          <div className={`${style.maintenance_image}`}></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForbiddenPage;
