import { FC,Fragment, useEffect } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { Link } from "react-router-dom";
import { Button, Modal, Grid } from 'semantic-ui-react';
import Boschicon from "../../../Common-components/BoschIcon/Boschicon";
import style from "./AccountInReviewModal.module.css";
import { useSelector } from "react-redux";
import { selectCustomerId } from "../../../redux-toolkit/accountReducer";
interface IAccountInReviewModalProps {
  isOpen: boolean;
  closeModel: () => void;
}
export const AccountInReviewModal: FC<IAccountInReviewModalProps> = ({
  isOpen,
  closeModel,
}) => {
  const { t } = useTranslation();
  const customerId = useSelector(selectCustomerId);
  const replaceHereLink = () => {
    let result = t("AccountInReviewModal_Description_Paragraph4");
    result = result.replace("[", `<a href="/contacts">`);
    result = result.replace("]", "</a>");
    return result;
  };
  
  return (
    <>
      <Modal
        dimmer={{
          className: `${style.welcome_cmd3_modal} ${style.success}`,
        }}
        className={style.modal}
        onClose={closeModel}
        open={isOpen}
        size={"tiny"}
      >
        <Modal.Header className={style.header}>
          <Boschicon name="bosch-ic-registration" />
          {t("WelcomeBatsModal_Title")}
        </Modal.Header>
        <Modal.Content className={style.content}>
          <Grid className={`${style.ui} ${style.grid}`}>
            <Grid.Row className={style.row}>
            {customerId === 0 && (
              <p className={style.activation_link}>
                <Link to="/my-dashboard/activate-account">
                  {t("WelcomeBatsModal_ActivationLink")}
                </Link>
                <small className={style.activation_link_notes}>
                  {t("WelcomeBatsModal_ActivationLinkNotes")}
                </small>
              </p>
            )}
              <p className={style.description_text}>
                {t("AccountInReviewModal_Description_Paragraph1")}
              </p>
              <p className={style.description_text}>
                {t("AccountInReviewModal_Description_Paragraph2")}
              </p>
              <p className={style.description_text}>
                {t("AccountInReviewModal_Description_Paragraph3")}
              </p>
              <p 
                className={style.description_text}
                dangerouslySetInnerHTML={{__html: replaceHereLink() }}
              />
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={style.actions}>
          <Button primary onClick={closeModel}>
            <div>{t("AccountInReviewModal_CloseButton")}</div>
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};


