import { useTranslation } from "@localization/contexts/i18nContext";
import { CurrencyHelper } from "../helpers/CurrencyHelper";
import { BatsCreditPrice, Price } from "../models/TrainingEvent";
import { DisplayPriceType } from "../redux-toolkit/accountReducer";

export const usePriceFormat = () => {
  const { t } = useTranslation();
  const formatPrice = (
    displayCredit: DisplayPriceType,
    price: Price,
    creditPrice: BatsCreditPrice,
    countryCode: string
  ) => {
    if (displayCredit === "currency") {
      if (!price) {
        return "";
      }

      return price?.displayVAT?  CurrencyHelper.format(
        price.price,
        price.currency.symbol,
        countryCode,
        "FREE"
      )
      : 
      CurrencyHelper.format(
        price.netPrice,
        price.currency.symbol,
        countryCode,
        "FREE"
      );

      /* return Constants.netPriceCountries.includes(countryCode)
        ? CurrencyHelper.format(
            price.netPrice,
            price.currency.symbol,
            countryCode,
            "FREE"
          )
        : CurrencyHelper.format(
            price.price,
            price.currency.symbol,
            countryCode,
            "FREE"
          ); */
    }

    if (!creditPrice) {
      return "";
    }

    return creditPrice.creditPrice
      ? `${creditPrice.creditPrice} ${
          creditPrice.creditPrice > 1
            ? t("ShoppingCart_CreditsPriceUnit")
            : t("ShoppingCart_CreditPriceUnit")
        }`
      : `${t("Course_Price_Free")}`;
  };
  return formatPrice;
};
