import { useTranslation } from "@localization/contexts/i18nContext";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  loginAsync,
  logoutAsync,
  selectAccount,
} from "../../../redux-toolkit/accountReducer";
import {
  selectMainContentPinned,
  setOpenSideNav,
  setRedirectURLCallback,
} from "../../../redux-toolkit/authReducer";
import { useUserAccountStatus } from "../../../hooks/useUserAccountStatus";
import { useUserRole } from "../../../hooks/useUserRole";
import { useEffect, useRef, useState } from "react";
import style from "./AccountButton.module.scss";
import CustomLoader from "../../../Common-components/loading/CustomLoader";
import ChangeCompanyModal from "../../../Common-components/CompanyModal/ChangeCompanyModal";
import { Button, Icon, MenuItem } from "@bosch/react-frok";
import {
  SIDE_NAV_MY_BOSCH_ITEM,
  SIDE_NAV_MY_TRAINING_ITEM,
  SIDE_NAV_WALLET_ITEM,
  SIDE_NAV_WORKSHOP_ITEM,
} from "../../../constants/sideNav.constant";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../../constants/frok.constant";
import { triggerSideNavigation } from "../../../utils/frok.util";
import { useAppDispatch, AppDispatch } from "../../../redux-toolkit/store";
import RDMessageDialog from "../../../components/RDComponents/RDMessageDialog/RDMessageDialog";
import { handleClickedOutOfComponent } from "../../../utils/dom.util";

const AccountButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const history = useHistory();
  const currentAccount = useSelector(selectAccount);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const { checkUserIsMapped, checkUserMultipleWorkshop } =
    useUserAccountStatus();
  const { isManager, isWholesaler } = useUserRole();
  const accountOptionsRef = useRef<HTMLDivElement | null>(null);
  const [isShowAccountOptions, setShowAccountOptions] = useState(false);
  const [isShowChangeCompanyModal, setShowChangeCompanyModal] = useState(false);
  const [isShowConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const checkFunction = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, accountOptionsRef, () =>
        setShowAccountOptions(false)
      );

    document.addEventListener("mousedown", checkFunction);

    return () => {
      document.removeEventListener("mousedown", checkFunction);
    };
  }, []);

  const getUserFullName = () => {
    if (!currentAccount) return "";

    return `${currentAccount?.employee?.firstName} ${currentAccount?.employee?.lastName}`;
  };

  const onChangPage = (path: string) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    history.push(path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  const onClickLoginButton = () => {
    if (currentAccount) {
      setShowAccountOptions((prev) => !prev);
      return;
    }

    // Mark the last location before login, then redirect after login.
    dispatchReduxToolkit(setRedirectURLCallback(window.location.href));

    setShowLoader(true);
    dispatch(loginAsync());
  };

  return (
    <>
      <div className={style.container}>
        <Button
          mode="integrated"
          icon={currentAccount ? "my-brand-frame" : "login"}
          onClick={onClickLoginButton}
        >
          <span>
            {currentAccount
              ? getUserFullName()
              : t("SideNavigation_AccountButton_RegisterOrLogin")}
          </span>
        </Button>
        {currentAccount ? (
          <div
            ref={accountOptionsRef}
            className={`${style.account_options} ${
              !!isShowAccountOptions ? style.show : ""
            }`}
          >
            <div className={style.header}>
              <p className={style.title}>{getUserFullName()}</p>
              <Icon
                iconName="close"
                onClick={() => setShowAccountOptions(false)}
              />
            </div>
            <div className={style.options_list}>
              {checkUserIsMapped() ? (
                <MenuItem
                  icon={SIDE_NAV_MY_TRAINING_ITEM.icon}
                  label={t(SIDE_NAV_MY_TRAINING_ITEM.label)}
                  onClick={() => onChangPage(SIDE_NAV_MY_TRAINING_ITEM.path)}
                  className={style.option}
                />
              ) : (
                <></>
              )}

              <MenuItem
                icon={SIDE_NAV_MY_BOSCH_ITEM.icon}
                label={t(SIDE_NAV_MY_BOSCH_ITEM.label)}
                onClick={() => onChangPage(SIDE_NAV_MY_BOSCH_ITEM.path)}
                className={style.option}
              />

              {checkUserMultipleWorkshop() ? (
                <MenuItem
                  icon="garage"
                  label={t("AccountButton_CompanyChange_MenuItem")}
                  onClick={() => {
                    setShowChangeCompanyModal(true);
                  }}
                  className={style.option}
                />
              ) : (
                <></>
              )}

              {isWholesaler ? (
                <MenuItem
                  icon={SIDE_NAV_WORKSHOP_ITEM.icon}
                  label={t(SIDE_NAV_WORKSHOP_ITEM.label)}
                  onClick={() => onChangPage(SIDE_NAV_WORKSHOP_ITEM.path)}
                  className={style.option}
                />
              ) : (
                <></>
              )}

              {isManager ? (
                <MenuItem
                  icon={SIDE_NAV_WALLET_ITEM.icon}
                  label={t(SIDE_NAV_WALLET_ITEM.label)}
                  onClick={() => onChangPage(SIDE_NAV_WALLET_ITEM.path)}
                  className={style.option}
                />
              ) : (
                <></>
              )}
              <MenuItem
                icon="logout"
                label={t("SideNavigation_AccountButton_Logout")}
                onClick={() => setShowConfirmLogoutModal(true)}
                className={style.option}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isShowChangeCompanyModal ? (
        <ChangeCompanyModal
          setCloseModalTrigger={() => {
            setShowChangeCompanyModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {isShowConfirmLogoutModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[t("Header_LogOutText")]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            dispatch(logoutAsync());
            setShowConfirmLogoutModal(false);
            setShowLoader(true);
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => {
            if (!isMainContentPinned) dispatch(setOpenSideNav(false));
            setShowConfirmLogoutModal(false);
          }}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AccountButton;
