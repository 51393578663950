import { Dialog } from "@bosch/react-frok";
import style from "./RDDialog.module.scss";
import { RDDialogProps } from "../../../types/component.type";
import { useTranslation } from "@localization/contexts/i18nContext";

const RDDialog = (props: RDDialogProps) => {
  const { t } = useTranslation();
  const { className, size, modal, open, title, ...restrictedProps } = props;

  const getAllClassNames = () => {
    const allClassNames: string[] = [style.container];

    allClassNames.push(style[size || "m"]);

    if (!!className) allClassNames.push(className);

    return allClassNames.join(" ");
  };

  const getDialogTitle = () => {
    if (!!title) return title;

    let result = "";

    switch (restrictedProps.variant) {
      case "info":
        result = t("Popup_Title_Info");
        break;
      case "warning":
        result = t("Popup_Title_Warning");
        break;
      case "success":
        result = t("AlertSuccess_Title");
        break;
      case "error":
        result = t("Popup_Title_Error");
        break;
      default:
        result = "";
    }

    return result;
  };

  return (
    <Dialog
      modal
      open
      title={getDialogTitle()}
      className={getAllClassNames()}
      {...restrictedProps}
    />
  );
};

export default RDDialog;
