import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useHistory } from "react-router";
import { Button, Grid, Modal } from "semantic-ui-react";

import Boschicon from "../../BoschIcon/Boschicon";
import Styles from "./AddElearningToCartSuccessModal.module.css";

interface AddElearningToCartSuccessModalProps {
  onDisplayModal: boolean;
  onCloseModalTrigger: Function;
  displayEventSuccessModel: AddElearningToCartSuccessInfo | null;
}

export interface AddElearningToCartSuccessInfo {
  trainingName: string;
}

const AddElearningToCartSuccessModal: FC<
  AddElearningToCartSuccessModalProps
> = (props: AddElearningToCartSuccessModalProps) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setOpenInformationModal(props.onDisplayModal);
  }, [props.onDisplayModal]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.onCloseModalTrigger();
  };

  const onRedirectToShoppingCart = () => {
    history.push("/shopping-cart");
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.addelearningtocartsuccess_modal_wrapper} ${Styles.success}`,
        }}
        onClose={closeInformationModal}
        open={openInformationModal}
        className={Styles.modal}
        size={"tiny"}
      >
        <Modal.Header className={Styles.header}>
          <Boschicon name="bosch-ic-alert-success" />
          {t("AddToCartSuccessModal_Message")}
        </Modal.Header>

        <Modal.Content className={Styles.content}>
          <Grid.Row className="row-info">
            <div className="common-modal-content">
              <div className="common-modal-content-section">
                <p>
                  <strong>
                    {props.displayEventSuccessModel?.trainingName}
                  </strong>{" "}
                  {`${t("AddElearningToCartSuccessModal_Message")}`}
                </p>
              </div>
            </div>
          </Grid.Row>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          <Button basic color="blue" onClick={closeInformationModal}>
            {t("AddToCartSuccessModal_ContinueButtonText")}
          </Button>
          <Button primary onClick={onRedirectToShoppingCart}>
            {t("AddToCartSuccessModal_MyShoppingCartButtonText")}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default AddElearningToCartSuccessModal;
