import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { usePriceFormat } from "../../hooks/usePriceFormat";
import { BatsCreditPrice, Price } from "../../models/TrainingEvent";
import { selectDisplayCredit } from "../../redux-toolkit/accountReducer";
import { selectCountryCode, selectIsSignIn } from "../../redux-toolkit/authReducer";
import Styles from "./PriceTagEx.module.css";
import { CurrencyHelper } from "../../helpers/CurrencyHelper";

interface PriceTagExProps {
  listPrice: Price;
  price: Price;
  yourSave: number;
  haveSave: boolean;

  listCreditPrice: BatsCreditPrice;
  creditPrice: BatsCreditPrice;
  yourCreditSave: number;
  haveCreditSave: boolean;

  isDisplayVATText?: boolean;
}

const PriceTagEx: FC<PriceTagExProps> = ({
  listPrice,
  price,
  yourSave,
  haveSave,
  listCreditPrice,
  creditPrice,
  yourCreditSave,
  haveCreditSave,
  isDisplayVATText,
}) => {
  const { t } = useTranslation();
  const formatPrice = usePriceFormat();
  const displayCredit = useSelector(selectDisplayCredit);
  const countryCode = useSelector(selectCountryCode);
  const isLoggedIn = useSelector(selectIsSignIn);
  const [yourSaveAmount, setYourSaveAmount] = useState<string>("");

  useEffect(() => {
      if(displayCredit === "currency"){
        setYourSaveAmount(yourSave  + price?.currency?.symbol);
      } else {
        let unit = yourCreditSave > 1 ? t("ShoppingCart_CreditsPriceUnit") : t("ShoppingCart_CreditPriceUnit");
        setYourSaveAmount(yourCreditSave + " " + unit);
      }
    }, [displayCredit, price?.currency?.symbol, yourCreditSave, yourSave]);

  return (
    <Fragment>
      {!isLoggedIn && (
        <>
          <span className={Styles.price_tag_wrapper}>
            {formatPrice(displayCredit, price, creditPrice, countryCode)}
          </span>
          {isDisplayVATText && (
            <>
              { !!price?.displayVAT?
              (
                <>
                  {!!price?.price && (
                    <p className={Styles.price_vat}>
                      {t("Common_IncludedVAT")}
                    </p>
                  )}
                </>
              ):
              (
                <>
                  {!!price?.netPrice && (
                    <p className={Styles.price_vat}>
                      {t("ShoppingCart_VatIsIncluded")}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {isLoggedIn && ((displayCredit === "currency" && (listPrice === null || listPrice?.price === 0)) || (displayCredit === "credit" && (listCreditPrice=== null || listCreditPrice?.creditPrice === 0))) && (<span className={Styles.price_tag_wrapper}>
            {formatPrice(displayCredit, listPrice, listCreditPrice, countryCode)}
          </span>)
      }

      {isLoggedIn && !((displayCredit === "currency" && (listPrice === null || listPrice?.price === 0)) || (displayCredit === "credit" && (listCreditPrice=== null || listCreditPrice?.creditPrice === 0))) && (         
        <div className={Styles.price_tag_wrapper}>
          <span className={Styles.list_price}>
            {formatPrice(displayCredit, listPrice, listCreditPrice, countryCode)}
          </span>
          <span className={Styles.price}>
            <span className={Styles.your_price}>{formatPrice(displayCredit, price, creditPrice, countryCode)}</span> <span  className={Styles.your_price_type}>{"(" + price?.priceType?.priceType +")"}</span>
          </span>

          <span className={Styles.your_save}>
            {t("PriceTag_YouSave_Label")} {yourSaveAmount}
          </span>         
 
          {isDisplayVATText && (
            <>
              { price?.displayVAT?
              (
                <>
                  {!!price?.price && (
                    <p className={Styles.price_vat}>
                      {t("Common_IncludedVAT")} 
                    </p>
                  )}
                </>
              ):
              (
                <>
                  {!!price?.netPrice && (
                    <p className={Styles.price_vat}>
                      {t("ShoppingCart_VatIsIncluded")}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}          
    </Fragment>
  );
};
export default PriceTagEx;
