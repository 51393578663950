import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
//import store from './redux/store/store';
import store from './redux-toolkit/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import I18nProvider from '@localization/providers/I18n.provider';

let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

