import { FC } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ShoppingCart } from "../../assets/svg/shoppingCart.svg";
import { useTranslation } from "@localization/contexts/i18nContext";
import Style from "./HeaderCartButton.module.css";
interface HeaderCartButtonProps {
  isMobile: boolean;
  style?: Object;
  className: string;
}
const HeaderCartButton: FC<HeaderCartButtonProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let params = useParams() as { workshopId: number | undefined };

  const redirectToPage = (url: string) => {
    let workshopId = params.workshopId;
    if (url === "/shopping-cart" && workshopId !== undefined) {
      history.push(`${url}/${workshopId}`);
    } else {
      history.push(url);
    }
  };

  // if (props.isMobile) {
  //   return (
  //     <Fragment>
  //       <Button
  //         className={props.className}
  //         style={props.style}
  //         basic
  //         //content={t('Header_Cart')}
  //         onClick={() => redirectToPage("/shopping-cart")}
  //         icon={
  //           <Icon style={{verticalAlign: "sub"}}>
  //             <ShoppingCart />
  //           </Icon>
  //         }
  //       ></Button>
  //     </Fragment>
  //   );
  // } else {
  //   return (
  //     <Fragment>
  //       <Button
  //         className={props.className}
  //         style={props.style}
  //         basic
  //         onClick={() => redirectToPage("/shopping-cart")}
  //         // content={t('Header_ShoppingCart')}
  //         icon={
  //           <Icon style={{verticalAlign: "sub"}}>
  //             <ShoppingCart />
  //           </Icon>
  //         }
  //       ></Button>
  //     </Fragment>
  //   );
  // }

  return (
    <div className={Style.cart_icon_container}>
      <Button
        className={props.className}
        style={props.style}
        basic
        // content={<span>{t('Header_Cart')}</span>}
        onClick={() => redirectToPage("/shopping-cart")}
        icon={
          <Icon className={Style.cart_icon}>
            <ShoppingCart />
          </Icon>
        }
      ></Button>
    </div>
  );
};

export default HeaderCartButton;
