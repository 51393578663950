import {
  SideNavigationItem,
  SideNavigationProps,
  SideNavigation as FrokSideNavigation,
  Icon,
} from "@bosch/react-frok";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useHistory } from "react-router-dom";
import { triggerSelectedSideNavGroupItem } from "../../utils/frok.util";
import SideNavigationBottom from "../sideNavigationBottom/SideNavigationBottom";
import style from "./SideNavigation.module.scss";
import { useSideNavConfig } from "../../hooks/pageConfiguration/useSideNavConfig";
import { MinimalSideNavItem } from "../../types/sideNavigation.type";
import {
  selectBatsVersion,
  selectCountryCode,
  selectMainContentPinned,
  selectOpenSideNav,
  setMainContentPinned,
  setOpenSideNav,
} from "../../redux-toolkit/authReducer";
import { useSelector } from "react-redux";
import { BATSVersion } from "../../models/pageConfiguration/BATSVersion";
import { applicationRouters } from "../../router/bats.router.config";
import {
  getCurrentRoutePath,
  getParentSideNavItem,
} from "../../utils/routing.util";
//import { useAppDispatch } from "../../redux/hooks";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { STATIC_SIDE_NAV_ITEM } from "../../constants/sideNav.constant";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../constants/frok.constant";
import { turnOfAllProxy } from "../../redux-toolkit/dashboardEmployeeReducer";
import { useMyDashboard } from "../../hooks/useMyDashboard";
import RDSideNavigationGroup from "../../components/RDComponents/RDSideNavigationGroup/RDSideNavigationGroup";

type Props = SideNavigationProps & {};

const SideNavigation = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const countryCode = useSelector(selectCountryCode);
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const batsVersion = useSelector(selectBatsVersion);
  const { sideNavItems } = useSideNavConfig();
  const [currentRoutePath, setCurrentRoutePath] = useState<string | undefined>(
    getCurrentRoutePath(
      applicationRouters[batsVersion as BATSVersion] || [],
      countryCode
    )
  );
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { headerBarMessage, isProxyMode } = useMyDashboard();

  const exitProxy = () => {
    dispatchReduxToolkit(turnOfAllProxy());
  };

  useEffect(() => {
    setCurrentRoutePath(
      getCurrentRoutePath(
        applicationRouters[batsVersion as BATSVersion] || [],
        countryCode
      )
    );
  }, [window?.location?.pathname, batsVersion]);

  useEffect(() => {
    if (sideNavItems.length === 0) return;

    setCurrentRoutePath(
      isOpenSideNav
        ? getCurrentRoutePath(
            applicationRouters[batsVersion as BATSVersion] || [],
            countryCode
          )
        : getParentSideNavItem(sideNavItems, countryCode)?.path
    );
  }, [isOpenSideNav, batsVersion, sideNavItems]);

  useEffect(() => {
    if (sideNavItems.length === 0) return;

    const currentRoutePath = getParentSideNavItem(
      sideNavItems,
      countryCode
    )?.path;

    if (!currentRoutePath) return;

    const timeoutId = window.setTimeout(() => {
      triggerSelectedSideNavGroupItem(currentRoutePath, !isOpenSideNav ?? true);
      window.clearTimeout(timeoutId);
    }, 25);
  }, [isOpenSideNav, batsVersion, sideNavItems]);

  const onChangePage = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: any
  ) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    if (event.currentTarget.parentElement?.ariaLabel === STATIC_SIDE_NAV_ITEM)
      return;

    history.push(data.value);
  };

  const renderSingleMenuItem = (
    route: MinimalSideNavItem,
    isSubItem: boolean
  ) => {
    return (
      <SideNavigationItem
        icon={isSubItem ? "" : route.icon}
        value={route.path}
        label={t(route.label)}
        onClick={() => exitProxy()}
      />
    );
  };

  const renderMultiLevelMenuItem = (route: MinimalSideNavItem) => {
    if (route?.isHideOnSideNavigation) return <></>;

    return (
      <RDSideNavigationGroup
        id={route.path}
        icon={route.icon}
        value={route.path}
        label={t(route.label)}
        isDefaultSelected={
          isOpenSideNav
            ? false
            : getParentSideNavItem(sideNavItems, countryCode)?.path ===
              route.path
        }
        subItemsCount={route.childItems ? route.childItems.length : 0}
      >
        {route.childItems?.map((childItem) =>
          renderSingleMenuItem(childItem, true)
        )}
      </RDSideNavigationGroup>
    );
  };

  const renderMenuItem = (route: MinimalSideNavItem) => {
    if (
      !route.childItems ||
      route.childItems.length === 0 ||
      route.childItems.every((item) => !!item.isHideOnSideNavigation)
    )
      return renderSingleMenuItem(route, false);

    return renderMultiLevelMenuItem(route);
  };

  const onOpenChange = (open: boolean, _?: React.SyntheticEvent) => {
    dispatch(setOpenSideNav(open));
  };

  const autoTriggerSideNav = (value: boolean) => {
    if (isMainContentPinned) return;

    if (
      window.screen.availWidth >= SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT &&
      isOpenSideNav !== value
    )
      dispatch(setOpenSideNav(value));
  };

  return (
    <FrokSideNavigation
      {...props}
      header={{
        label: t("SideNavigation_Title"),
      }}
      open={!!isOpenSideNav}
      onOpenChange={onOpenChange}
      onSelectedItemChange={onChangePage}
      defaultSelectedItem={currentRoutePath}
      selectedItem={currentRoutePath}
      className={`${style.container} ${isOpenSideNav ? style.open : ""}`}
      onMouseOver={() => autoTriggerSideNav(true)}
      onMouseLeave={() => autoTriggerSideNav(false)}
    >
      <button
        className={`a-button a-button--integrated -without-label m-side-navigation__header__trigger ${
          style.pin_button
        } ${isOpenSideNav ? style.pin_button_shown : ""}`}
        onClick={() => dispatch(setMainContentPinned())}
      >
        <Icon
          iconName={
            isMainContentPinned ? "unpin-classic-disabled-light" : "pin-classic"
          }
          className="a-button__icon"
        />
      </button>
      <div className={style.wrapper}>
        {sideNavItems.map((item, index) => (
          <Fragment key={index}>{renderMenuItem(item)}</Fragment>
        ))}
      </div>
      <SideNavigationBottom />
    </FrokSideNavigation>
  );
};

export default SideNavigation;
