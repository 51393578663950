import { Icon, MenuItem } from "@bosch/react-frok";
import style from "./AccountOptions.module.scss";
import { useSelector } from "react-redux";
import { selectAccount } from "../../../redux-toolkit/accountReducer";
import { useEffect, useRef, useState } from "react";
import {
  SIDE_NAV_MY_BOSCH_ITEM,
  SIDE_NAV_MY_TRAINING_ITEM,
  SIDE_NAV_WALLET_ITEM,
  SIDE_NAV_WORKSHOP_ITEM,
  STATIC_SIDE_NAV_ITEM,
} from "../../../constants/sideNav.constant";
import { useTranslation } from "@localization/contexts/i18nContext";
import { handleClickedOutOfComponent } from "../../../utils/dom.util";
//import { useUserSubscription } from "../../../hooks/useUserSubscription";
import { useHistory } from "react-router-dom";
import ChangeCompanyModal from "../../../Common-components/CompanyModal/ChangeCompanyModal";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../../constants/frok.constant";
import { triggerSideNavigation } from "../../../utils/frok.util";
import { useUserAccountStatus } from "../../../hooks/useUserAccountStatus";
import { useUserRole } from "../../../hooks/useUserRole";
import {
  selectMainContentPinned,
  setOpenSideNav,
} from "../../../redux-toolkit/authReducer";
import { useAppDispatch } from "../../../redux/hooks";
import RDSideNavigationItem from "../../../components/RDComponents/RDSideNavigationItem/RDSideNavigationItem";

const AccountOptions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentAccount = useSelector(selectAccount);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const { checkUserIsMapped, checkUserMultipleWorkshop } =
    useUserAccountStatus();
  const { isManager, isWholesaler } = useUserRole();
  const accountOptionsRef = useRef<HTMLDivElement | null>(null);
  const [isShowAccountOptions, setShowAccountOptions] = useState(false);
  const [isShowChangeCompanyModal, setShowChangeCompanyModal] = useState(false);

  useEffect(() => {
    const checkFunction = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, accountOptionsRef, () =>
        setShowAccountOptions(false)
      );

    document.addEventListener("mousedown", checkFunction);

    return () => {
      document.removeEventListener("mousedown", checkFunction);
    };
  }, []);

  const getUserFullName = () => {
    if (!currentAccount) return "";

    return `${currentAccount?.employee?.firstName} ${currentAccount?.employee?.lastName}`;
  };

  const onChangPage = (path: string) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    history.push(path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  if (!currentAccount) return <></>;

  return (
    <>
      <div className={`${style.container} ${style.hide_on_desktop}`}>
        <RDSideNavigationItem
          icon="user"
          label={getUserFullName()}
          title={getUserFullName()}
          onClick={() => setShowAccountOptions((prev) => !prev)}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
        <div
          ref={accountOptionsRef}
          className={`${style.account_options} ${
            !!isShowAccountOptions ? style.show : ""
          }`}
        >
          <div className={style.header}>
            <p className={style.title}>{getUserFullName()}</p>
            <Icon
              iconName="close"
              onClick={() => setShowAccountOptions(false)}
            />
          </div>
          <div className={style.options_list}>
            {checkUserIsMapped() ? (
              <MenuItem
                icon={SIDE_NAV_MY_TRAINING_ITEM.icon}
                label={t(SIDE_NAV_MY_TRAINING_ITEM.label)}
                onClick={() => onChangPage(SIDE_NAV_MY_TRAINING_ITEM.path)}
                className={style.option}
              />
            ) : (
              <></>
            )}

            <MenuItem
              icon={SIDE_NAV_MY_BOSCH_ITEM.icon}
              label={t(SIDE_NAV_MY_BOSCH_ITEM.label)}
              onClick={() => onChangPage(SIDE_NAV_MY_BOSCH_ITEM.path)}
              className={style.option}
            />

            {checkUserMultipleWorkshop() ? (
              <MenuItem
                icon="garage"
                label={t("AccountButton_CompanyChange_MenuItem")}
                onClick={() => {
                  setShowChangeCompanyModal(true);
                }}
                className={style.option}
              />
            ) : (
              <></>
            )}

            {isWholesaler ? (
              <MenuItem
                icon={SIDE_NAV_WORKSHOP_ITEM.icon}
                label={t(SIDE_NAV_WORKSHOP_ITEM.label)}
                onClick={() => onChangPage(SIDE_NAV_WORKSHOP_ITEM.path)}
                className={style.option}
              />
            ) : (
              <></>
            )}

            {isManager ? (
              <MenuItem
                icon={SIDE_NAV_WALLET_ITEM.icon}
                label={t(SIDE_NAV_WALLET_ITEM.label)}
                onClick={() => onChangPage(SIDE_NAV_WALLET_ITEM.path)}
                className={style.option}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {isShowChangeCompanyModal ? (
        <ChangeCompanyModal
          setCloseModalTrigger={() => {
            setShowChangeCompanyModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AccountOptions;
