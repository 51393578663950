import { Button } from "@bosch/react-frok";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useHistory } from "react-router-dom";
import { CONTACT_US_SHARED_ROUTE } from "../../../constants/routes/sharedRoutes.constants";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../../constants/frok.constant";
import { triggerSideNavigation } from "../../../utils/frok.util";

const ContactButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onGoToContactPage = () => {
    history.push(CONTACT_US_SHARED_ROUTE.path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  return (
    <Button mode="integrated" icon="mail" onClick={onGoToContactPage}>
      <span>{t("Header_ContactUs")}</span>
    </Button>
  );
};

export default ContactButton;
