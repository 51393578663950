import { createContext, useContext } from "react";
import { TRANSLATIONS_EN } from "../languageKeys/en/translations";

export type LanguageTranslationType = Record<string, string>;

export const CHANGE_LANGUAGE_DEBOUNCE_TIME = 1500;

export const DEFAULT_TRANSLATIONS: LanguageTranslationType = TRANSLATIONS_EN;

export type TFunction = (
  translationKey: string,
  interpolation?: LanguageTranslationType
) => string;

export interface I18nState {
  isLoadingTranslations: boolean;
  currentLcid: string;
  defaultTranslations: LanguageTranslationType;
  localizedTranslations: LanguageTranslationType;
  t: TFunction;
  changeLanguage: (localizedLanguageLcid: string) => void;
  initReactI18next: (localizedLanguageLcid?: string) => void;
}

export const I18N_INIT_STATE: I18nState = {
  isLoadingTranslations: true,
  currentLcid: "",
  defaultTranslations: {},
  localizedTranslations: {},
  t: () => "",
  changeLanguage: () => Promise.resolve(),
  initReactI18next: () => Promise.resolve(),
};

export const I18nContext = createContext<I18nState>(I18N_INIT_STATE);

export const useTranslation = () => useContext(I18nContext);
