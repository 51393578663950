import { FC, Fragment, useState } from "react";
import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";
import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
import { ReactComponent as Coins } from "../../assets/svg/coins.svg";

import { useTranslation } from "@localization/contexts/i18nContext";
import Boschicon from "../BoschIcon/Boschicon";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsSignIn } from "../../redux-toolkit/authReducer";
import {
  selectCustomerId,
  selectImList,
  selectUserRoleId,
} from "../../redux-toolkit/accountReducer";
import Styles from "./AccountButton.module.css";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { turnOfAllProxy } from "../../redux-toolkit/dashboardEmployeeReducer";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";

interface AccountButtonProps {
  isMobile: boolean;
  style?: Object;
  className: string;
  login: Function;
  logout: Function;
  changeCompany: Function;
}
const AccountButton: FC<AccountButtonProps> = (props) => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsSignIn);
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const impersonationList = useSelector(selectImList);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const handleSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    if (data.value === "log_out") {
      setIsOpenLogOutModal(true);
    } else if (data.value === "company") {
      props.changeCompany();
    } else {
      dispatchReduxToolkit(turnOfAllProxy());
    }
  };

  const checkUserMultipleWorkshop = () => {
    return !!impersonationList && impersonationList.length > 1;
  };

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const userRoleId = useSelector(selectUserRoleId);
  const customerId = useSelector(selectCustomerId);
  const allOptions = [
    {
      key: "dashboard",
      text: t("MenuHeader_MyTraining"),
      value: "dashboard",
      icon: <Boschicon name="bosch-ic-desktop-dashboard" />,
      as: Link,
      to: "/dashboard",
      role: [10, 20, 30],
      showForNotMapAccount: false,
    },
    {
      key: "Myprofile",
      text: t("MyProfile_Profile"),
      value: "Myprofile",
      icon: <Profile style={{ width: "1.5rem" }} />,
      as: Link,
      to: "/myprofile",
      role: [10, 20, 30],
      showForNotMapAccount: true,
    },
    {
      key: "workshops",
      text: t("AccountButton_Workshops_MenuItem"),
      value: "workshops",
      icon: <Boschicon name="bosch-ic-garage" />,
      role: [30],
      as: Link,
      to: "/dashboard/my-workshops",
      showForNotMapAccount: false,
    },
    {
      key: "company",
      text: t("AccountButton_CompanyChange_MenuItem"),
      value: "company",
      icon: <Boschicon name="bosch-ic-garage" />,
      role: [10, 20, 30],
      showForNotMapAccount: false,
      isHide: !checkUserMultipleWorkshop(),
    },
    {
      key: "wallet",
      text: t("AccountButton_Wallet_MenuItem"),
      value: "wallet",
      isCDM2: false,
      icon: <Coins style={{ width: "1.5rem" }} />,
      as: Link,
      to: "/dashboard/my-wallet",
      role: [10],
      showForNotMapAccount: false,
    },
    {
      key: "log_out",
      icon: <Boschicon name="bosch-logout" />,
      text: t("Header_LogOut"),
      isCDM2: true,
      value: "log_out",
      role: [10, 20, 30],
      showForNotMapAccount: true,
    },
  ];

  const options = customerId
    ? allOptions.filter((x: any) => x.role.includes(userRoleId))
    : allOptions.filter(
        (x: any) => x.role.includes(userRoleId) && !!x.showForNotMapAccount
      );

  if (isLoggedIn) {
    return (
      <Fragment>
        <Button labelPosition="left" id={Styles[props.className]}>
          <Dropdown
            className={Styles.menu_options}
            id={Styles.menu_options_id}
            options={options.filter((x: any) => !x.isHide)}
            icon={
              <Icon>
                <Profile />
              </Icon>
            }
            onChange={handleSelection}
            fluid
            closeOnChange={true}
            value=""
          />
        </Button>

        {/* <RDAlert
          size="mini"
          type="warning"
          isOpen={isOpenLogOutModal}
          messages={[t("Header_LogOutText")]}
          acceptButtonText={t("CurrentUserAccountDeactivationModal_Yes")}
          onAccept={() => {
            setIsLoggingOut(true);
            props.logout();
          }}
          cancelButtonText={t("CurrentUserAccountDeactivationModal_No")}
          onCancel={() => setIsOpenLogOutModal(false)}
        /> */}

        <RDCustomAlert
          size="mini"
          type="warning"
          isOpen={isOpenLogOutModal}
          AcceptButton={
            <RDButton
              primary
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setIsLoggingOut(true);
                props.logout();
              }}
              disabled={isLoggingOut}
              loading={isLoggingOut}
            >
              {t("CurrentUserAccountDeactivationModal_Yes")}
            </RDButton>
          }
          CancelButton={
            <RDButton
              secondary
              style={{ borderRadius: 0 }}
              onClick={() => setIsOpenLogOutModal(false)}
            >
              {t("CurrentUserAccountDeactivationModal_No")}
            </RDButton>
          }
        >
          <Grid>
            <Grid.Row>
              <p>{t("Header_LogOutText")}</p>
            </Grid.Row>
          </Grid>
        </RDCustomAlert>

        {/* <Modal
          onClose={() => setIsOpenLogOutModal(false)}
          open={isOpenLogOutModal}
          dimmer={{ className: "test-dimmer logout-modal warning" }}
          closeOnDimmerClick={false}
          size={"mini"}
          className={Styles.logout_modal}
        >
          <Modal.Content>
           <p>{t("Header_LogOutText")}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={isLoggingOut}
              disabled={isLoggingOut}
              className="bwc-btn--primary"
              onClick={() => {
                setIsLoggingOut(true);
                props.logout();
              }}
            >
              {t("CurrentUserAccountDeactivationModal_Yes")}
              {""}
            </Button>
            <Button
              disabled={isLoggingOut}
              className="bwc-btn--secondary"
              onClick={() => setIsOpenLogOutModal(false)}
            >
              {t("CurrentUserAccountDeactivationModal_No")}
              {""}
            </Button>
          </Modal.Actions>
        </Modal> */}
      </Fragment>
    );
  } else {
    // if (props.isMobile) {
    //   return (
    //     <Fragment>
    //       <Button
    //         id={Styles[props.className]}
    //         style={props.style}
    //         basic
    //         onClick={() => props.login()}
    //         // content={t("AccountButton_LoginButton_mobile")}
    //         icon={
    //           <Icon style={{ verticalAlign: "sub" }}>
    //             <Profile />
    //           </Icon>
    //         }
    //       ></Button>
    //     </Fragment>
    //   );
    // } else {
    return (
      <Button
        id={Styles[props.className]}
        style={props.style}
        basic
        onClick={() => props.login()}
        content={""}
        icon={
          <Icon style={{ verticalAlign: "sub" }}>
            <Profile />
          </Icon>
        }
      ></Button>
    );
    // }
  }
};

export default AccountButton;
