import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useHistory } from "react-router";
import { Button, Grid, Modal, Table } from "semantic-ui-react";

import Boschicon from "../../BoschIcon/Boschicon";
import "./AddToCartSuccessModal.css";
import { useAccountSettings } from "../../../hooks/useAccountSettings";

interface AddToCartSuccessModalProps {
  onDisplayModal: boolean;
  onCloseModalTrigger: Function;
  displayEventSuccessModel: AddToCartEventModalInfo | null;
}

export interface AddToCartEventModalInfo {
  trainingName: string;
  date: string;
  days: number;
  location: string;
}

const AddToCartSuccessModal: FC<AddToCartSuccessModalProps> = (
  props: AddToCartSuccessModalProps
) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [displayAddedEventInfo, setDisplayAddedEventInfo] =
    useState<AddToCartEventModalInfo | null>();
  const history = useHistory();
  const getDateFormat = useAccountSettings();
  useEffect(() => {
    setDisplayAddedEventInfo(props.displayEventSuccessModel);
    setOpenInformationModal(props.onDisplayModal);
  }, [props.onDisplayModal]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.onCloseModalTrigger();
  };

  const onRedirectToShoppingCart = () => {
    history.push("/shopping-cart");
  };

  return (
    <Fragment>
      <Modal
        dimmer={{ className: "addtocartsuccess-modal-wrapper" }}
        onClose={closeInformationModal}
        open={openInformationModal}
        size={"small"}
      >
        <Modal.Header>
          <div>
            <Boschicon name="bosch-ic-alert-success" />
            {t("AddToCartSuccessModal_Message")}
          </div>
        </Modal.Header>
        <Modal.Content className="courseinfoview-modal">
          <Grid>
            {displayAddedEventInfo != null && (
              <Grid.Row className="addtocartmodal-eventAdded-info">
                <Table basic="very" unstackable className="flex-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="mobile tablet">
                        {t("TrainingCatalogue_TrainingName")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="mobile tablet">
                        {t("TrainingCatalogue_StartDate")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="align-right">
                        {t("TrainingCatalogue_Days")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="tablet">
                        {t("TrainingCatalogue_Location")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="mobile tablet">
                        {displayAddedEventInfo.trainingName}
                      </Table.Cell>
                      <Table.Cell className="mobile tablet">
                        {displayAddedEventInfo.date}
                      </Table.Cell>
                      <Table.Cell className="align-right">
                        {displayAddedEventInfo.days}
                      </Table.Cell>
                      <Table.Cell className="tablet">
                        {displayAddedEventInfo.location}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Row>
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="blue" onClick={closeInformationModal}>
            {t("AddToCartSuccessModal_ContinueButtonText")}
          </Button>
          <Button primary onClick={onRedirectToShoppingCart}>
            {t("AddToCartSuccessModal_MyShoppingCartButtonText")}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default AddToCartSuccessModal;
