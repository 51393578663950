import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";

import "./EvaluationRestrictionModal.css";
import RDAlert from "../../../RDComponents/RDAlert";
interface EvaluationRestrictionModalProps {
  onDisplayModal: boolean;
  onCloseModalTrigger: Function;
}

const EvaluationRestrictionModal: FC<EvaluationRestrictionModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);

  useEffect(() => {
    if (!openInformationModal) {
      props.onCloseModalTrigger();
    }
  }, [openInformationModal]);

  useEffect(() => {
    setOpenInformationModal(props.onDisplayModal);
  }, [props.onDisplayModal]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
  };

  return (
    <Fragment>
      <RDAlert
        size="small"
        type="warning"
        isOpen={openInformationModal}
        title={t("CourseDetail_EvaluationRestriction_Title")}
        messages={[
          t("CourseDetail_EvaluationRestriction_Message"),
          t("CourseDetail_EvaluationRestriction_AdditionalMessage"),
        ]}
        acceptButtonText={t("CommonButton_Close")}
        onAccept={() => {
          closeInformationModal();
        }}
      />      
    </Fragment>
  );
};

export default EvaluationRestrictionModal;
