import { ReactNode, useEffect, useState } from "react";
import {
  DEFAULT_TRANSLATIONS,
  I18N_INIT_STATE,
  I18nContext,
  LanguageTranslationType,
} from "../contexts/i18nContext";

import { useAppDispatch } from "../../redux-toolkit/store";
import { getLanguageTranslationByLcid } from "../../services/TranslationToolService";
import { TRANSLATIONS_APPID } from "@constants/translationTool.constants";

const I18nProvider = ({ children }: { children: ReactNode }) => {
  const [isLoadingTranslations, setLoadingTranslations] = useState(
    I18N_INIT_STATE.isLoadingTranslations
  );
  const [currentLcid, setCurrentLcid] = useState(I18N_INIT_STATE.currentLcid);
  const [defaultTranslations, setDefaultTranslations] =
    useState<LanguageTranslationType>(I18N_INIT_STATE.defaultTranslations);
  const [localizedTranslations, setLocalizedTranslations] =
    useState<LanguageTranslationType>(I18N_INIT_STATE.localizedTranslations);

  useEffect(() => {
    initReactI18next("");
  }, []);

  const dispatch = useAppDispatch();

  const loadDefaultTranslations = (): LanguageTranslationType => {
    return DEFAULT_TRANSLATIONS;
  };

  const convertString = (str: string) => {
    // Check if the string already contains a dash
    if (!str.includes('-')) {
      // If no dash, make the string uppercase and append it after a dash
      return str + '-' + str.toUpperCase();
    } else {
      // If it contains a dash, just return the string as is
      return str;
    }
  }

  const loadLocalizedLanguages = async (
    localizedLanguageLcid?: string
  ): Promise<LanguageTranslationType> => {
    if (!localizedLanguageLcid) {
      return {};
    }

    const normalizedLocalizedLanguageLcid = localizedLanguageLcid.trim();

    if (!normalizedLocalizedLanguageLcid) {
      return DEFAULT_TRANSLATIONS;
    }

    //Convert lcid singel solitary;
    let convertedLcid = convertString(normalizedLocalizedLanguageLcid);


    setCurrentLcid(convertedLcid);

    try {
      const getLanguageTranslationByLcidResponse = await dispatch(
        getLanguageTranslationByLcid({
          appId: TRANSLATIONS_APPID.BATS,
          lcid: convertedLcid,
        })
      );

      if (getLanguageTranslationByLcid.fulfilled.match(getLanguageTranslationByLcidResponse)) {
        if (!!getLanguageTranslationByLcidResponse.payload && !!getLanguageTranslationByLcidResponse.payload.dataObject) {
          const translateValues = getLanguageTranslationByLcidResponse.payload?.dataObject?.translations;
          const isEmpty = Object.keys(translateValues).length === 0;

          return !isEmpty ? translateValues : DEFAULT_TRANSLATIONS;
        }
      }

      return DEFAULT_TRANSLATIONS;
    } catch (error) {
      console.error("Get Translation Error", error);
      return DEFAULT_TRANSLATIONS; // Return default translations on error
    }
  };

  const t = (
    translationKey: string,
    interpolation?: LanguageTranslationType
  ) => {
    const normalizedTranslationKey = translationKey.trim();

    if (!normalizedTranslationKey) return "";

    let translatedValue = localizedTranslations[normalizedTranslationKey] ||
      defaultTranslations[normalizedTranslationKey] ||
      translationKey;

    if (translatedValue === normalizedTranslationKey || !interpolation)
      return translatedValue;

    const interpolationKeys = Object.keys(interpolation);

    if (interpolationKeys.length === 0) return translatedValue;

    const interpolationValues = Object.values(interpolation);

    for (let i = 0; i < interpolationKeys.length; ++i)
      translatedValue = translatedValue.replace(
        `{{${interpolationKeys[i]}}}`,
        interpolationValues[i] || ""
      );

    return translatedValue;
  };

  const changeLanguage = async (localizedLanguageLcid: string) => {
    const normalizedLocalizedLanguageLcid = localizedLanguageLcid.trim();

    if (
      !normalizedLocalizedLanguageLcid ||
      normalizedLocalizedLanguageLcid === currentLcid
    )
      return;

    setLoadingTranslations(true);
    let updateData = await loadLocalizedLanguages(normalizedLocalizedLanguageLcid);

    setLocalizedTranslations(
      updateData
    );
    setLoadingTranslations(false);

  };

  const initReactI18next = async (localizedLanguageLcid?: string) => {
    setDefaultTranslations(loadDefaultTranslations());
    setLocalizedTranslations(await loadLocalizedLanguages(localizedLanguageLcid));
    setLoadingTranslations(false);
  };

  return (
    <I18nContext.Provider
      value={{
        isLoadingTranslations,
        currentLcid,
        defaultTranslations,
        localizedTranslations,
        t,
        changeLanguage,
        initReactI18next,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
