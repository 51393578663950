import { FC, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Grid, Icon, Transition } from "semantic-ui-react";
import {
  selectCustomerId,
  selectUserRoleId,
} from "../../redux-toolkit/accountReducer";
import {
  authSetLanguage,
  selectAuthState,
  selectCountryName,
  selectIsSignIn,
} from "../../redux-toolkit/authReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import "./MenuHeaderPopup.css";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { ReactComponent as Regions } from "../../assets/svg/locator.svg";
import HeaderCartButton from "./HeaderCartButton";
import Styles from "./MenuHeaderPopup.module.css";
import Boschicon from "../BoschIcon/Boschicon";
import AccountButton from "./AccountButton";
import { turnOfAllProxy } from "../../redux-toolkit/dashboardEmployeeReducer";
import RDLanguageSelection from "../RDComponents/RDLanguageSelection/RDLanguageSelection";
import { LanguageOption } from "../../models/general/LanguageModel";

interface openPopupProps {
  isOpen: boolean;
  type: string;
}

interface MenuHeaderPopupProp {
  hideMenu: Function;
  login: Function;
  logout: Function;
  setIsSearch: Function;
  setIsOpenSwitchRegion: Function;
  setLoginOpen: Function;
  profileOpen: boolean;
  languageOptions: any[];
  changeCompany: Function;
}

const MenuHeaderPopup: FC<MenuHeaderPopupProp> = (props) => {
  const history = useHistory();
  const { t, changeLanguage } = useTranslation();
  const customerId = useSelector(selectCustomerId);
  const isLoggedIn = useSelector(selectIsSignIn);
  const [openPopup, setOpenPopup] = useState<openPopupProps>({
    isOpen: false,
    type: "",
  });
  const [languageOpen, setLanguageOpen] = useState(false);
  const [language, setLanguage] = useState<string>(
    useSelector(selectAuthState).language
  );
  const countryName = useSelector(selectCountryName);
  const userRoleId = useSelector(selectUserRoleId);
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const switchLanguage = (data: any) => {
    setLanguage(data);
    changeLanguage(data.trim());
    dispatch(authSetLanguage(data));
  };

  const getLanguageOptions = (): LanguageOption[] => {
    if (authState.languages && authState.languages.length > 0)
      return authState.languages.map((lang) => {
        return {
          key: lang.localized,
          text: lang.localized,
          value: lang.lcid,
        };
      });

    return [
      {
        key: "English",
        text: "English",
        value: "en-US",
      },
    ];
  };

  const renderLevel2 = (type: string) => {
    switch (type) {
      case "trainingServices":
        return (
          <Grid.Column id={Styles.header_level2_col}>
            <Grid.Row>
              <Button
                basic
                icon={<Boschicon name="bosch-ic-arrow-left" />}
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                    type: "",
                  });
                }}
                id={Styles.header_level2_col_title}
                content={<span> {t("MenuHeader_TrainingServices")} </span>}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span>{t("Footer_TrainingServices")}</span>}
                onClick={() => {
                  history.push("/training-course-catalogue");
                }}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span>{t("EventCalendar_EventCalendar")}</span>}
                onClick={() => {
                  history.push("/event-calendar");
                }}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span>{t("Dashboard_Employee_Program")}</span>}
                onClick={() => {
                  history.push("/training-program");
                }}
              ></Button>
            </Grid.Row>            
          </Grid.Column>
        );
      case "myTraining":
        return (
          <Grid.Column id={Styles.header_level2_col}>
            <Grid.Row>
              <Button
                basic
                icon={<Boschicon name="bosch-ic-arrow-left" />}
                onClick={() => {
                  dispatchReduxToolkit(turnOfAllProxy());
                  setOpenPopup({
                    isOpen: false,
                    type: "",
                  });
                }}
                id={Styles.header_level2_col_title}
                content={<span> {t("MenuHeader_MyTraining")} </span>}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span> {t("Dashboard_Headline_YourOverview")}</span>}
                onClick={() => {
                  dispatchReduxToolkit(turnOfAllProxy());
                  setOpenPopup({
                    isOpen: false,
                    type: "",
                  });
                  props.hideMenu();
                  history.push("/dashboard/:activetab");
                }}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span>{t("Dashboard_MenuItem_Curricula")}</span>}
                onClick={() => {
                  dispatchReduxToolkit(turnOfAllProxy());
                  setOpenPopup({
                    isOpen: false,
                    type: "",
                  });
                  props.hideMenu();
                  history.push("/dashboard/my-training-program");
                }}
              ></Button>
            </Grid.Row>
            {userRoleId === 30 && (
              <>
                <Grid.Row>
                  <Button
                    basic
                    id={Styles.button_override}
                    content={<span>{t("Footer_Workshops")}</span>}
                    onClick={() => {
                      dispatchReduxToolkit(turnOfAllProxy());
                      setOpenPopup({
                        isOpen: false,
                        type: "",
                      });
                      props.hideMenu();
                      history.push("/dashboard/my-workshops");
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    basic
                    id={Styles.button_override}
                    content={<span>{t("Wallet")}</span>}
                    onClick={() => {
                      dispatchReduxToolkit(turnOfAllProxy());
                      setOpenPopup({
                        isOpen: false,
                        type: "",
                      });
                      props.hideMenu();
                      history.push("/dashboard/my-wallet");
                    }}
                  ></Button>
                </Grid.Row>
              </>
            )}
            {userRoleId === 10 && (
              <>
                <Grid.Row>
                  <Button
                    basic
                    id={Styles.button_override}
                    content={<span>{t("Dashboard_MenuItem_Team")}</span>}
                    onClick={() => {
                      dispatchReduxToolkit(turnOfAllProxy());
                      setOpenPopup({
                        isOpen: false,
                        type: "",
                      });
                      props.hideMenu();
                      history.push("/dashboard/my-employees");
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    basic
                    id={Styles.button_override}
                    content={<span>{t("Dashboard_MenuItem_External")}</span>}
                    onClick={() => {
                      dispatchReduxToolkit(turnOfAllProxy());
                      setOpenPopup({
                        isOpen: false,
                        type: "",
                      });
                      props.hideMenu();
                      history.push("/dashboard/my-suppliers");
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    basic
                    id={Styles.button_override}
                    content={<span>{t("Dashboard_MenuItem_Wallet")}</span>}
                    onClick={() => {
                      dispatchReduxToolkit(turnOfAllProxy());
                      setOpenPopup({
                        isOpen: false,
                        type: "",
                      });
                      props.hideMenu();
                      history.push("/dashboard/my-wallet");
                    }}
                  ></Button>
                </Grid.Row>
              </>
            )}
          </Grid.Column>
        );
      case "support":
        return (
          <Grid.Column id={Styles.header_level2_col}>
            <Grid.Row>
              <Button
                basic
                icon={<Boschicon name="bosch-ic-arrow-left" />}
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                    type: "",
                  });
                }}
                id={Styles.header_level2_col_title}
                content={<span>{t("MenuHeader_Support")}</span>}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span>{t("Breadcrumb_Download_Page")}</span>}
                onClick={() => {
                  history.push("/download-files");
                }}
              ></Button>
            </Grid.Row>
            <Grid.Row>
              <Button
                basic
                id={Styles.button_override}
                content={<span> {t("MenuHeader_FAQ")} </span>}
                onClick={() => {
                  history.push("/support");
                }}
              ></Button>
            </Grid.Row>
          </Grid.Column>
        );
      default:
        return <span></span>;
    }
  };

  return (
    <div className="grid-responsive-width">
      <Grid columns={1} className="menu-header-items menu-header-items-mobile">
        <Grid.Column>
          <Grid.Row columns={2} className="menu-header-items-mobile-row">
            <Grid.Column>
              <Button
                className="header-icon-button"
                basic
                onClick={() => props.hideMenu()}
                icon={
                  <Icon className="icon-close">
                    <Close />
                  </Icon>
                }
              ></Button>
            </Grid.Column>
            <Grid.Column className="menu-header-mobile-icons">
              <AccountButton
                className="icon_mobile"
                isMobile={true}
                login={() => {
                  props.login();
                }}
                logout={() => {
                  props.logout();
                }}
                changeCompany={() => {
                  props.changeCompany();
                }}
              />
              <Button
                className="header-icon-button"
                basic
                onClick={() => {
                  props.hideMenu();
                  props.setIsOpenSwitchRegion();
                }}
                icon={
                  <Icon>
                    <Regions />
                  </Icon>
                }
              ></Button>
              <Button
                className="header-icon-button"
                basic
                onClick={() => {
                  props.hideMenu();
                  props.setIsSearch(true);
                }}
                icon={
                  <Icon>
                    <Search />
                  </Icon>
                }
              ></Button>
              <HeaderCartButton
                className="header-icon-button header-cart"
                isMobile={false}
              />
              {/* <PopupNew
                basic
                id="lang-test"
                offset={[0, 0]}
                position="bottom left"
                trigger={
                  <Button
                    className="header-icon-button header-language-icon"
                    basic
                    content={
                      <span className="language-text">
                        {language.slice(0, 2).toLowerCase()}
                      </span>
                    }
                    icon={
                      <Icon className="language-icon">
                        <GlobeLanguage />
                      </Icon>
                    }
                  ></Button>
                }
                hideOnScroll
                eventsEnabled={true}
                on="click"
                onClose={() => setLanguageOpen(false)}
                onOpen={() => setLanguageOpen(true)}
                style={{ borderRadius: 0, padding: 0 }}
                open={languageOpen}
              >
                <Menu
                  vertical
                  style={{ borderRadius: 0 }}
                  className="pick-language-box"
                  styles={{ borderRadius: "10px" }}
                >
                  <Menu.Item header className="available-languages-header">
                    {t("Header_Available_Languages")}
                  </Menu.Item>
                  {props.languageOptions.map((x: any) => (
                    <Menu.Item
                      className="language-options"
                      name={x.text}
                      onClick={() => {
                        setLanguageOpen(false);
                        switchLanguage(x.value);
                      }}
                    ></Menu.Item>
                  ))}
                </Menu>
              </PopupNew> */}

              <RDLanguageSelection
                currentLanguage={language}
                languages={getLanguageOptions()}
                onChangeLanguage={(newLang) => switchLanguage(newLang)}
              />
            </Grid.Column>
          </Grid.Row>
          {isLoggedIn && !!customerId && (
            <Grid.Row>
              <Button
                basic
                className={Styles.buttons_mul_level}
                icon={<Boschicon name="bosch-forward-right" />}
                content={<span> {t("MenuHeader_MyTraining")}</span>}
                onClick={() => {
                  setOpenPopup({
                    isOpen: true,
                    type: "myTraining",
                  });
                }}
              ></Button>
            </Grid.Row>
          )}
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_mul_level}
              icon={<Boschicon name="bosch-forward-right" />}
              content={<span> {t("MenuHeader_TrainingServices")} </span>}
              onClick={() => {
                setOpenPopup({
                  isOpen: true,
                  type: "trainingServices",
                });
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_link}
              icon={<Boschicon name="bosch-forward-right" />}
              content={<span> {t("MenuHeader_OurLocations")} </span>}
              onClick={() => {
                setOpenPopup({
                  isOpen: true,
                  type: "outLocations",
                });
                history.push("/training-centers");
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_mul_level}
              icon={<Boschicon name="bosch-forward-right" />}
              content={<span> {t("MenuHeader_Support")} </span>}
              onClick={() => {
                setOpenPopup({
                  isOpen: true,
                  type: "support",
                });
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_link}
              icon={<Boschicon name="bosch-forward-right" />}
              content={<span> {t("MyProfile_Profile")} </span>}
              onClick={() => {
                history.push("/myprofile");
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_link}
              icon={<Boschicon name="bosch-forward-right" />}
              content={<span> {t("Footer_ShoppingCart")}</span>}
              onClick={() => {
                history.push("/shopping-cart");
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            {isLoggedIn ? (
              <Button
                basic
                content={<span> {t("MenuHeader_Logout")} </span>}
                className={Styles.buttons_link}
                icon={<Boschicon name="bosch-forward-right" />}
                onClick={() => {
                  props.hideMenu();
                  props.logout();
                }}
              />
            ) : (
              <Button
                basic
                content={<span> {t("Homepage_Login")}</span>}
                className={Styles.buttons_link}
                icon={<Boschicon name="bosch-forward-right" />}
                onClick={() => {
                  props.login();
                }}
              />
            )}
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={Styles.buttons_link}
              content={<span>{t("MenuHeader_ContactsUs")}</span>}
              icon={<Boschicon name="bosch-forward-right" />}
              onClick={() => {
                history.push("/contacts");
              }}
            ></Button>
          </Grid.Row>
          <Grid.Row>
            <Button
              basic
              className={`${Styles.locations}`}
              content={<span>{countryName}</span>}
              icon={<Boschicon name="bosch-ic-locator" />}
              onClick={() => {
                props.setIsOpenSwitchRegion();
              }}
            ></Button>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid columns={1} className={Styles.header_level2}>
        <Grid.Column>
          <Transition
            visible={openPopup.isOpen}
            animation="slide left"
            duration={300}
          >
            {renderLevel2(openPopup.type)}
          </Transition>
        </Grid.Column>
      </Grid>
      <div></div>
    </div>
  );
};

export default MenuHeaderPopup;
