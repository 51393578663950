import { FC, Fragment, useEffect, useState } from "react";

import { Grid } from "semantic-ui-react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { Link } from "react-router-dom";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import {
  getEnrollmentDetailsAsync,
  selectEnrollmentId,
  setEnrollmentDetail,
  widthdrawEventAsync,
} from "../../redux-toolkit/dashboardReducer";
import { ReqeustWidthdrawEventModel } from "../../models/dashboard/request.GetMyTrainingEvents.model";
import ResponseResultBase from "../../models/ResponseResultBase";
import {
  selectAccount,
  selectCiamId,
  selectOriginComp,
} from "../../redux-toolkit/accountReducer";
import { useSelector } from "react-redux";
import {
  selectAuthState,
  selectCountryCode,
  selectLanguage,
  selectTimeZoneId,
} from "../../redux-toolkit/authReducer";
import { TrainingEvent } from "../../models/TrainingEvent";
import "./CourseWithdrawalModal.css";
import {
  selectSelectedWorkshop,
  selectWholesalerProxyMode,
} from "../../redux-toolkit/dashboardEmployeeReducer";
import { EnrollmentDetails } from "../../models/EventDetail";
import RDAlert from "../RDComponents/RDAlert";
import RDButton from "../RDComponents/RDButton";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDAlertCustomTitle from "../RDComponents/RDAlertCustomTitle";
import { RequestGetEnrollmentDetailsModel } from "../../models/dashboard/request.GetEnrollmentDetails.model";
interface CourseWithdrawalModalProps {
  item: TrainingEvent | null;
  enrollmentDetail: EnrollmentDetails | null;
  enrollmentId: number;
  openModal: boolean;
  closeCourseWithdrawalModal: Function;
  employeeCustomerId: number;
  onParentCall?: Function;
  onWithdrawSuccess: Function;
  enablePendingWithdrawal?: boolean;
}

const CourseWithdrawalModal: FC<CourseWithdrawalModalProps> = (
  props: CourseWithdrawalModalProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openCourseWithdrawalModal, setOpenCourseWithdrawalModal] =
    useState(false);
  const [isReqeustingWithdraw, setIsReqeustingWithdraw] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isWithdrawSuccess, setIsWithdrawSuccess] = useState(false);
  const currentAccount = useSelector(selectAccount);
  const authState = useSelector(selectAuthState);

  const wholesalerProxyMode = useSelector(selectWholesalerProxyMode);
  const selectedWorkshop = useSelector(selectSelectedWorkshop);

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const originAccount = useSelector(selectOriginComp);
  const ciamId = useSelector(selectCiamId);
  const timeZoneId = useSelector(selectTimeZoneId);
  const selectedEnrollmentId = useSelector(selectEnrollmentId);

  useEffect(() => {
    setOpenCourseWithdrawalModal(props.openModal);
    //localStorage.setItem('my-request-form',JSON.stringify(requestData))
  }, [props.openModal]);

  const termAndConditionCode = () => {
    if (!!authState?.language && authState?.language.trim() !== "") {
      return authState?.language.toLowerCase().trim();
    }
    return "en";
  };

  const closeModal = () => {
    setOpenCourseWithdrawalModal(false);
    props.closeCourseWithdrawalModal();
    if (props.onParentCall) {
      props.onParentCall();
    }
  };

  const sendWithdrawalRequest = () => {
    setIsReqeustingWithdraw(true);

    let userCustomerID = props.employeeCustomerId;
    if (wholesalerProxyMode) {
      userCustomerID = selectedWorkshop?.customerId ?? 0;
    }

    const widthdrawEventRequest: ReqeustWidthdrawEventModel = {
      ciamId: currentAccount?.ciamId ?? "",
      customerId: userCustomerID,
      country: authState?.countryCode ?? "",
      language: authState?.language ?? "",
      timeZoneId: authState?.timeZoneId ?? "",
      salesRegion: "",
      enrollmentId: props.enrollmentId,
    };

    dispatch(widthdrawEventAsync(widthdrawEventRequest))
      .then((data) => {
        if (data?.payload) {
          const response = data.payload as ResponseResultBase<any>;
          if (response?.success) {
            setOpenCourseWithdrawalModal(false);
            setIsWithdrawSuccess(true);
          } else {
            setIsOpenErrorModal(true);
          }
        }
      })
      .finally(() => {
        setIsReqeustingWithdraw(false);
      });
  };

  const sendPendingWithdrawalRequest = () => {
    setIsReqeustingWithdraw(true);

    let userCustomerID = props.employeeCustomerId;
    if (wholesalerProxyMode) {
      userCustomerID = selectedWorkshop?.customerId ?? 0;
    }

    const widthdrawEventRequest: ReqeustWidthdrawEventModel = {
      ciamId: currentAccount?.ciamId ?? "",
      customerId: userCustomerID,
      country: authState?.countryCode ?? "",
      language: authState?.language ?? "",
      timeZoneId: authState?.timeZoneId ?? "",
      salesRegion: "",
      enrollmentId: props.enrollmentId,
    };

    dispatch(widthdrawEventAsync(widthdrawEventRequest))
      .then((data) => {
        if (data?.payload) {
          const response = data.payload as ResponseResultBase<any>;
          if (response?.success) {
            setOpenCourseWithdrawalModal(false);
            setIsWithdrawSuccess(true);
          } else {
            setIsOpenErrorModal(true);
          }
        }
      })
      .finally(() => {
        setIsReqeustingWithdraw(false);
      });
  };

  const reloadEnrollmentDetail = () => {
    let userCustomerID = originAccount?.customerId;
    if (wholesalerProxyMode) {
      userCustomerID = selectedWorkshop?.customerId ?? 0;
    }

    // Get enrolment detail base on courseID
    const enrollmentInput: RequestGetEnrollmentDetailsModel = {
      enrollmentId: selectedEnrollmentId || 0,
      ciamId: ciamId ?? "",
      customerId: userCustomerID ?? 0,
      country: countryCode ?? "",
      language: language ?? "",
      timeZoneId: timeZoneId ?? "",
    };

    const getDetails = dispatch(getEnrollmentDetailsAsync(enrollmentInput));
    getDetails.then((data: any) => {
      if (data?.payload?.dataObject) {
        dispatchReduxToolkit(setEnrollmentDetail(data.payload.dataObject));
      }
    });
  };

  return (
    <Fragment>
      {props.enablePendingWithdrawal && (
        <RDAlertCustomTitle
          size="tiny"
          isOpen={openCourseWithdrawalModal}
          type={"warning"}
          title={t("CourseWithdrawalModal_PendingWithdraw_Title")}
          messages={[
            t("CourseWithdrawalModal_PendingWithdraw_Message1"),
            t("CourseWithdrawalModal_PendingWithdraw_Message2"),
            t("CourseWithdrawalModal_PendingWithdraw_Message3")
              .replace(
                "[cancellation policy]",
                "[" +
                  t(
                    "CourseWithdrawalModal_PendingWithdraw_CancellationPolicy"
                  ) +
                  "]"
              )
              .replace(
                "[",
                `<u> <a target="_blank" href="` +
                  `/term-condition/${termAndConditionCode()}` +
                  `">`
              )
              .replace("]", "</a></u>"),
          ]}
          cancelButtonText={t(
            "CourseWithdrawalModal_PendingWithdraw_CancelButton"
          )}
          onCancel={closeModal}
          acceptButtonText={t(
            "CourseWithdrawalModal_PendingWithdraw_ContinueButton"
          )}
          onAccept={() => {
            sendPendingWithdrawalRequest();
          }}
        />
      )}

      {!props?.enablePendingWithdrawal && (
        <RDCustomAlert
          type="warning"
          isOpen={openCourseWithdrawalModal}
          CancelButton={
            <RDButton
              secondary
              style={{ borderRadius: 0 }}
              onClick={closeModal}
            >
              {t("CourseWithdrawalModal_No")}
            </RDButton>
          }
          AcceptButton={
            <RDButton
              primary
              style={{ marginRight: "1rem" }}
              onClick={sendWithdrawalRequest}
              disabled={isReqeustingWithdraw}
              loading={isReqeustingWithdraw}
            >
              {t("CourseWithdrawalModal_Yes")}
            </RDButton>
          }
        >
          <Grid>
            <Grid.Row>
              <p>{t("CourseWithdrawalModal_Line1")}</p>
              <p>
                {t("CourseWithdrawalModal_Line2Part1")}
                <Link
                  to={`/term-condition/${termAndConditionCode()}`}
                  style={{ paddingLeft: "2px", paddingRight: "4px" }}
                  target="_blank"
                >
                  <u>{t("CourseWithdrawalModal_Line2Part2Link")}</u>
                </Link>
                {t("CourseWithdrawalModal_Line2Part3")}
              </p>
            </Grid.Row>
          </Grid>
        </RDCustomAlert>
      )}

      {isOpenErrorModal && props.enrollmentDetail && (
        <RDAlert
          isOpen={isOpenErrorModal}
          type="error"
          title={t("ErrorCommon_Header")}
          messages={[t("Error_Common")]}
          acceptButtonText={t("CommonButton_Close")}
          onAccept={() => {
            setIsOpenErrorModal(false);
          }}
        />
      )}

      {isWithdrawSuccess && props.enrollmentDetail && (
        <RDAlert
          isOpen={isWithdrawSuccess}
          type="success"
          title={props.enrollmentDetail?.eventDetails?.trainingName}
          messages={[t("CourseWithdrawalModal_WithdrawSuccessMessage")]}
          acceptButtonText={t("CommonButton_Close")}
          onAccept={() => {
            setIsWithdrawSuccess(false);
            if (props?.enablePendingWithdrawal) {
              reloadEnrollmentDetail();
            } else if (props.onWithdrawSuccess) props.onWithdrawSuccess();
          }}
        />
      )}
      
    </Fragment>
  );
};

export default CourseWithdrawalModal;
