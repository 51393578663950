import { FC, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useSelector } from "react-redux";
import RedirectHelper from "../../helpers/RedirectHelper";
import { selectAccount } from "../../redux-toolkit/accountReducer";
import {
  authSetSignIn,
  selectCountryCode,
  selectIsSignIn,
} from "../../redux-toolkit/authReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { AuthService } from "../../services/AuthService";
import "./CountryValidationModal.css";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";
import Styles from "./CountryValidationModal.module.css";
import { resetTrainingCourses } from "../../redux-toolkit/trainingCourseReducer";
import { resetTrainingPrograms } from "../../redux-toolkit/trainingProgramReducer";
import { resetTrainingEvents } from "../../redux-toolkit/trainingEventReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";

interface CountryValidationModalProps {}

export const CountryValidationModal: FC<CountryValidationModalProps> = ({}) => {
  const { t } = useTranslation();
  const account = useSelector(selectAccount);
  const isSignIn = useSelector(selectIsSignIn);
  const countryCode = useSelector(selectCountryCode);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const [loggedInCountry, setLoggedInCountry] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCountryCode, setUserCountryCode] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (
      isSignIn &&
      account &&
      !!countryCode &&
      !!account.companyCountry &&
      account.companyCountry?.toUpperCase() !== countryCode?.toUpperCase()
    ) {
      const regions = AuthService.getRegionsLanguages();
      const userCountry = regions.find(
        (x) =>
          x.country.trim().toLowerCase() ===
          account.companyCountry?.trim().toLowerCase()
      );
      const loggedinCountry = regions.find(
        (x) =>
          x.country.trim().toLowerCase() === countryCode.trim().toLowerCase()
      );

      setLoggedInCountry(loggedinCountry?.countryName || "");
      setUserCountry(userCountry?.countryName || "");
      setUserCountryCode(account.companyCountry);

      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [countryCode, account]);

  const messageContent = () => {
    const message = t("CountryValidationModal_Message");
    return message
      .replace("[CountryA]", `<b>${loggedInCountry}</b>`)
      .replace("[CountryB]", `<b>${userCountry}</b>`);
  };

  const buttonText = () => {
    const text = t("CountryValidationModal_ButtonText");
    return text.replace("[Country]", `<b>${userCountry}</b>`);
  };

  const redirectToRightCountry = () => {
    setIsRedirecting(true);
    setTimeout(() => {
      dispatchReduxToolkit(authSetSignIn(true));
      const regions = AuthService.getRegionsLanguages();
      const regionSelection = regions.find(
        (x) =>
          x.country.trim().toLowerCase() ===
          userCountryCode.trim().toLowerCase()
      );
      if (regionSelection) {
        const language = regionSelection
          ? regionSelection.languages.find((l) => l.defaultLanguage)
          : null;
        RedirectHelper.redirectToRightCountry(
          regionSelection.country,
          language ? language.lcid : ""
        );
      }

      forceToReloadData();
    }, 1000);
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  return (
    <>
      {/* <Modal
        style={{ borderRadius: "0" }}
        className="redirect-country-modal"
        dimmer={{ className: "test-dimmer" }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => { }}
        open={isOpenModal}
        size={"tiny"}
      >
        <Modal.Content className="modal-content-custom">
          <div>
            <Boschicon
              style={{ color: "rgb(255, 230, 35)", fontSize: "3rem" }}
              name="bosch-ic-alert-warning-filled"
            />
          </div>
          <p dangerouslySetInnerHTML={{
            __html: messageContent()
          }}>

          </p>
          <Grid.Row className="button-custom">
            <Button
              color="blue"
              style={{ width: "50%" }}
              onClick={redirectToRightCountry}
              loading={isRedirecting}
            >
              <span dangerouslySetInnerHTML={{ __html: buttonText() }}></span>
            </Button>
          </Grid.Row>
        </Modal.Content>
      </Modal> */}

      <RDCustomAlert
        isOpen={isOpenModal}
        type="warning"
        AcceptButton={
          <RDButton
            primary
            onClick={redirectToRightCountry}
            disabled={isRedirecting}
          >
            <span dangerouslySetInnerHTML={{ __html: buttonText() }}></span>
          </RDButton>
        }
      >
        <div className={`${Styles.message} ${Styles.message_override}`}>
          <p
            dangerouslySetInnerHTML={{
              __html: messageContent(),
            }}
          ></p>
        </div>
      </RDCustomAlert>
    </>
  );
};
