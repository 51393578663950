import { User } from "oidc-client-ts";
import { FC } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { Button, Grid, Item, Modal, Popup } from "semantic-ui-react";
import { Account, ContactPerson } from "../../../models/Account";
import "./ConfirmItsMeModal.css";

interface IConfirmItsMeModalProps {
  isOpen: boolean;
  contactPerson: ContactPerson | null;
  account: Account | null;
  ciamAccount: User | null;
  userConfirm: () => void;
}

export const ConfirmItsMeModal: FC<IConfirmItsMeModalProps> = ({
  isOpen,
  contactPerson,
  account,
  ciamAccount,
  userConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        style={{ borderRadius: "0" }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {}}
        open={isOpen}
        size={"large"}
      >
        <Modal.Content>
          <section className="confirm-modal-intro">
            <h2>
              {t("ConfirmItsMeModal_Hi")} {contactPerson?.firstName}{" "}
              {contactPerson?.lastName}
            </h2>
            <p>{t("ConfirmItsMeModal_Description1")}</p>
            <p>{t("ConfirmItsMeModal_Description2")}</p>
          </section>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <h4>{t("ConfirmItsMeModal_CompanyDetails")}</h4>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Name")}:{"  "}
                {!!account
                  ? account?.companyName
                  : (ciamAccount?.profile.company as any)?.companyName}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Address")}:{"  "}
                {!!account
                  ? account?.address1
                  : (ciamAccount?.profile.company as any)?.address1}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Postcode")}:{"  "}
                {!!account
                  ? account?.postCode
                  : (ciamAccount?.profile.company as any)?.zipCode}{" "}
                {!!account ? account?.city : (ciamAccount?.profile.company as any)?.city}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Phone")}:{"  "}
                {!!account
                  ? account?.city
                  : (ciamAccount?.profile.company as any)?.phone}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Email")}:{"  "}
                {account?.companyEmail}
              </p>
            </Grid.Column>
            <Grid.Column>
              <h4>{t("ConfirmItsMeModal_YourDetails")}</h4>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Title")}:{"  "}
                {contactPerson?.title}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Function")}:{"  "}
                {contactPerson?.employeeType}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Name")}:{"  "}
                {contactPerson?.firstName} {contactPerson?.lastName}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Mobile")}:{"  "}
                {contactPerson?.mobilePhone}
              </p>
              <p className="confirm-your-email">
                {t("ConfirmItsMeModal_YourDetails_Email")}:{"  "}
                <Popup
                  className="custom-popup"
                  trigger={<span>{contactPerson?.email}</span>}
                >
                  {contactPerson?.email}
                </Popup>
              </p>

              <p>
                {t("ConfirmItsMeModal_YourDetails_Language")}:{"  "}
                {contactPerson?.language?.language}
              </p>
            </Grid.Column>
          </Grid>
          <p className="confirm-modal-description3">
            {t("ConfirmItsMeModal_Description3")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <Button
              className="bwc-btn--primary"
              style={{ width: "30%" }}
              onClick={() => {
                userConfirm();
              }}
            >
              {t("ConfirmItsMeModal_ConfirmButton")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
