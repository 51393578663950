import _ from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@localization/contexts/i18nContext";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import landingPageImage from "../../assets/LandingImage.jpg";
import AccordionPanel from "../../Common-components/Accordion/AccordionPanel";
import Header from "../../Common-components/Header/Header";
import RedirectHelper from "../../helpers/RedirectHelper";
import { Language } from "../../models/RegionState";
import {
  getRegionsAsync,
  selectRegionState
} from "../../redux-toolkit/regionReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import { AuthService } from "../../services/AuthService";
import "./LandingPage.css";
import LandingPageNew from "./LandingPage/LandingPageNew";

const LandingPage: FC<RouteComponentProps> = (
  props: RouteComponentProps<{}>
) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const regionState = useSelector(selectRegionState);
  const [regionsData, setRegionsData] = useState<any>([]);
  useEffect(() => {
    async function performGetRegions() {
      if (!!regionState && !!regionState.regions) {
        AuthService.setRegionsLanguages(regionState.regions);
        const regions = _.groupBy(regionState.regions, "area");
        setRegionsData(regions);
      } else {
        let response = await dispatchReduxToolkit(getRegionsAsync());
        if (getRegionsAsync.fulfilled.match(response)) {
          if (!!response.payload && response.payload.success) {
            if (response.payload.dataObject != null) {
              AuthService.setRegionsLanguages(response.payload.dataObject);
              const regions = _.groupBy(response.payload.dataObject, "area");
              setRegionsData(regions);
            }
          }
        }
      }
    }
    performGetRegions();
  }, []);

  const handleSetLanguageClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    countryCode: string,
    language: Language,
    url: string,
    clientId: string,
  ) => {
    event.preventDefault();
    if (!url) {
      RedirectHelper.redirectToMainSite();
    }

    const countryCodeCheck = countryCode.trim().toUpperCase();
    switch (countryCodeCheck) {
      case "BA":
        RedirectHelper.redirectToSubDomain(url, "HR", "hr", countryCodeCheck);
        break;
      case "SL":
        RedirectHelper.redirectToSubDomain(url, "HR", "sl", countryCodeCheck);
        break;
      default:
        if (url !== "") {
          RedirectHelper.redirectToSubDomain(url, countryCode, language.lcid, countryCodeCheck);
        } else {
          window.location.href = "/";
        }
        break;
    }
  };

  const items = Object.entries(regionsData)?.map(
    ([key, value]: [string, any]) => {
      const countryContent = value?.map((countryItem: any, index: any) => {
        return (
          <Grid.Column
            key={countryItem.country}
            computer={5}
            tablet={8}
            mobile={16}
            style={{ alignItems: "baseline", paddingRight: "0.5rem" }}
          >
            {countryItem.languages.map((language: Language) => {
              if (language.defaultLanguage) {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="country-label"
                    href="#"
                    onClick={(e) => {
                      handleSetLanguageClick(
                        e,
                        countryItem.country,
                        language,
                        countryItem.url,
                        countryItem.clientId
                      );
                    }}
                  >
                    {" "}
                    {countryItem.countryName}
                  </a>
                );
              }
            })}
          </Grid.Column>
        );
      });

      const alignedContent = (
        <Grid
          container
          style={{
            alignItems: "baseline",
            display: "flex",
          }}
        >
          {countryContent}
        </Grid>
      );
      return { title: key, content: alignedContent };
    }
  );

  return (
    <Fragment>
      {/* <Header isInLandingPage={true}></Header>
      <div
        className="grid-responsive-width body-container landing-body-container"
        style={{ paddingBottom: "3rem" }}
      >
        <Grid container className="landing-content-container">
          <Grid.Row className="title-row">
            <h1 className="main-headline">{t("LandingPage_Title")}</h1>
          </Grid.Row>
          <Grid.Row className="landing-image">
            <Image
              className="home-img"
              src={landingPageImage}
              style={{ left: "0%", width: "110%", maxWidth: "110%" }}
            ></Image>
          </Grid.Row>
        </Grid>
        <h3
          style={{ textAlign: "center", paddingTop: "3rem" }}
          className="accordion-header-text"
        >
          {t("LandingPage_SubTitle_ChooseCountry")}{" "}
        </h3>
        <AccordionPanel
          items={items}
          activeindex={0}
          divider
          titleClass="landing-according-title"
        />
      </div> */}
      <LandingPageNew history={props.history} location={props.location} match={props.match}/>
    </Fragment>
  );
};

export default LandingPage;
