import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  authSetLanguage,
  selectAuthState,
  selectLanguage,
  selectMainContentPinned,
  setOpenSideNav,
} from "../../../redux-toolkit/authReducer";
import style from "./LangSelection.module.scss";
import { useTranslation } from "@localization/contexts/i18nContext";
import { AppDispatch, useAppDispatch } from "../../../redux-toolkit/store";
//importAppDispatch,  { useAppDispatch } from "../../../redux/hooks";
import { Icon } from "@bosch/react-frok";
import { STATIC_SIDE_NAV_ITEM } from "../../../constants/sideNav.constant";
import RDDropdown from "../../../components/RDComponents/RDDropdown";
import { LanguageOption } from "../../../models/pageConfiguration/LanguageModel";
import { handleClickedOutOfComponent } from "../../../utils/dom.util";

import {
  resetAssignedTrainingPrograms,
  resetTrainingPrograms,
} from "../../../redux-toolkit/trainingProgramReducer";
import { resetContactPersons } from "../../../redux-toolkit/contactReducer";
import { resetTrainingCourses } from "../../../redux-toolkit/trainingCourseReducer";
import { resetTrainingEvents } from "../../../redux-toolkit/trainingEventReducer";
import { resetTrainingCenters } from "../../../redux-toolkit/trainingCenterReducer";
import {
  resetEventEnrollmentSummaryList,
  resetMyTrainingEvents,
} from "../../../redux-toolkit/dashboardReducer";
import RDSideNavigationItem from "../../../components/RDComponents/RDSideNavigationItem/RDSideNavigationItem";

const LangSelection = () => {
  const { changeLanguage, t } = useTranslation();
  // const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthState);
  const language = useSelector(selectLanguage);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const langSelectionRef = useRef<HTMLDivElement | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [isShowLangSelection, setIsShowLangSelection] = useState(false);

  const dispatch = useDispatch();
  //const authState = useSelector(selectAuthState);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  useEffect(() => {
    setCurrentLanguage(language);
  }, [language]);

  useEffect(() => {
    const checkFunction = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, langSelectionRef, () =>
        setIsShowLangSelection(false)
      );

    document.addEventListener("mousedown", checkFunction);

    return () => {
      document.removeEventListener("mousedown", checkFunction);
    };
  }, []);

  const getLangOptions = () => {
    return !!authState.languages && authState.languages.length > 0
      ? authState.languages.map((x) => {
        return {
          key: x.localized,
          text: x.localized,
          value: x.lcid,
        };
      })
      : [
        {
          key: "English",
          text: "English",
          value: "en-US",
        },
      ];
  };

  const switchLanguage = (data: any) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    setCurrentLanguage(data);
    changeLanguage(data.trim());
    dispatch(authSetLanguage(data));
    setIsShowLangSelection(false);

    // Force to reload data
    reloadPersonalData();
    forceToReloadData();
  };

  if (!authState.languages) return <></>;

  const reloadPersonalData = () => {
    // clear all assigned training programs
    dispatchReduxToolkit(resetAssignedTrainingPrograms(undefined));

    // clear all assigned training events
    dispatchReduxToolkit(resetMyTrainingEvents(undefined));

    // clear all contact persons
    dispatchReduxToolkit(resetContactPersons(undefined));

    // force to reload team EventEnrollmentSummaryList data.
    dispatchReduxToolkit(resetEventEnrollmentSummaryList(undefined));
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));
  };

  return (
    <div className={style.container}>
      <RDSideNavigationItem
        icon="globe-language"
        label={t("SideNavigation_Language")}
        title={t("SideNavigation_Language")}
        className={style.option}
        onClick={() => setIsShowLangSelection((prev) => !prev)}
        aria-label={STATIC_SIDE_NAV_ITEM}
      />
      <div
        ref={langSelectionRef}
        className={`${style.lang_selection} ${!!isShowLangSelection ? style.show : ""
          }`}
      >
        <div className={style.header}>
          <p className={style.title}>{t("SideNavigation_SelectLanguage")}</p>
          <Icon
            iconName="close"
            onClick={() => setIsShowLangSelection(false)}
          />
        </div>
        <RDDropdown
          value={currentLanguage}
          defaultValue={currentLanguage}
          options={getLangOptions()}
          onChange={(_, data: any) =>
            switchLanguage((data as LanguageOption).value)
          }
        />
      </div>
    </div>
  );
};

export default LangSelection;
