import { useTranslation } from "@localization/contexts/i18nContext";
import style from "./SideNavigationBottom.module.scss";
import {
  loginAsync,
  logoutAsync,
  selectAccount,
} from "../../redux-toolkit/accountReducer";
import { useSelector } from "react-redux";
import { useState } from "react";
import { SideNavigationItem } from "@bosch/react-frok";

import RDMessageDialog from "../../components/RDComponents/RDMessageDialog/RDMessageDialog";
import { useHistory } from "react-router-dom";
import {
  selectCountryName,
  selectMainContentPinned,
  setOpenSideNav,
  setRedirectURLCallback,
} from "../../redux-toolkit/authReducer";
import LangSelection from "../../features/sideNavigationBottom/langSelection/LangSelection";
import { STATIC_SIDE_NAV_ITEM } from "../../constants/sideNav.constant";
//import AccountOptions from "../../features/sideNavigationBottom/accountOptions/AccountOptions";

import { CONTACT_US_SHARED_ROUTE } from "../../constants/routes/sharedRoutes.constants";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../constants/frok.constant";
import { triggerSideNavigation } from "../../utils/frok.util";
import CustomLoader from "../../Common-components/loading/CustomLoader";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import AccountOptions from "../../features/sideNavigationBottom/accountOptions/AccountOptions";
import RDSideNavigationItem from "../../components/RDComponents/RDSideNavigationItem/RDSideNavigationItem";

const SideNavigationBottom = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const currentAccount = useSelector(selectAccount);
  const countryName = useSelector(selectCountryName);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isShowConfirmLogoutModal, setIsShowConfirmLogoutModal] =
    useState(false);
  const [isShowConfirmChangeRegionsModal, setIsShowConfirmChangeRegionsModal] =
    useState(false);

  const onGoToRegionsPage = () => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    setIsShowConfirmChangeRegionsModal(true);
  };

  const onLogIn = () => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    if (currentAccount) setIsShowConfirmLogoutModal(true);
    else {
      setIsShowLoader(true);

      // Mark the last location before login, then redirect after login.
      dispatchReduxToolkit(setRedirectURLCallback(window.location.href));
      dispatchReduxToolkit(loginAsync());
    }

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  const onGoToContactPage = () => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    history.push(CONTACT_US_SHARED_ROUTE.path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  return (
    <>
      <div className={style.container}>
        <LangSelection />
        <SideNavigationItem
          value=""
          icon="locator"
          label={countryName}
          title={countryName}
          link={{
            as: "button",
            onClick: () => onGoToRegionsPage(),
          }}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
        {currentAccount ? <AccountOptions /> : <></>}
        <SideNavigationItem
          value=""
          icon={currentAccount ? "logout" : "login"}
          label={t(
            currentAccount
              ? "SideNavigation_AccountButton_Logout"
              : "SideNavigation_AccountButton_RegisterOrLogin"
          )}
          title={t(
            currentAccount
              ? "SideNavigation_AccountButton_Logout"
              : "SideNavigation_AccountButton_RegisterOrLogin"
          )}
          link={{
            as: "button",
            onClick: () => onLogIn(),
          }}
          className={style.hide_on_desktop}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
        <RDSideNavigationItem
          icon="mail"
          label={t("SideNavigation_ConntactUs")}
          title={t("SideNavigation_ConntactUs")}
          onClick={() => onGoToContactPage()}
          aria-label={STATIC_SIDE_NAV_ITEM}
          isSelected={window.location.pathname.includes(
            CONTACT_US_SHARED_ROUTE.path
          )}
          className={style.hide_on_desktop}
        />
      </div>
      {isShowLoader ? <CustomLoader enable /> : <></>}
      {isShowConfirmLogoutModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[t("Header_LogOutText")]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            dispatch(logoutAsync());
            setIsShowConfirmLogoutModal(false);
            setIsShowLoader(true);
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => {
            if (!isMainContentPinned) dispatch(setOpenSideNav(false));
            setIsShowConfirmLogoutModal(false);
          }}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
      {isShowConfirmChangeRegionsModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[
            `${t("Header_CurrentRegion")} ${countryName}.`,
            t("Header_ChangeRegions"),
          ]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            if (!isMainContentPinned) dispatch(setOpenSideNav(false));
            history.push("/regions");
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => {
            if (!isMainContentPinned) dispatch(setOpenSideNav(false));
            setIsShowConfirmChangeRegionsModal(false);
          }}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SideNavigationBottom;
